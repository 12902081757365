import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import { useEffect } from 'react'
import { useLocation, withRouter } from 'react-router-dom'

import { useTranslation } from 'react-i18next'

import CookieConsent from 'react-cookie-consent'

import {
  PATH_ROOT,
  PATH_USE_CASES,
  PATH_MARKET_PLACES,
  PATH_ABOUT,
  PATH_BOARD_DIRECTORS,
  PATH_EXECUTIVE_MANAGEMENT,
  PATH_TALKTOUS,
  PATH_IMPRESSUM,
  PATH_IMPRINT,
  PATH_PRIVACY_POLICY,
  PATH_KLARPAY,
  PATH_404,
  PATH_GOOGLE_PAY,
  PATH_APPLE_PAY,
  PATH_CARD,
  PATH_NEWS_AND_MEDIA,
  PATH_NEWS_ENTRIES,
  PATH_MESSAGE_SENT,
  PATH_VIRTUAL_IBAN_ACCOUNTS,
  PATH_CORRESPONDENT_BANKS,
  PATH_DATA_PROCESSORS,
  PATH_CONNECTIVITY,
  PATH_ENTREPRENEUR_ACCOUNTS,
  PATH_PARTNERSHIPS,
  PATH_FAQ,
  PATH_NEWS_PAGES,
  PATH_NEWS_PAGES_CATEGORY,
  PATH_NEWS_PAGES_CATEGORY_PAGES,
  PATH_BUSINESS_ACCOUNT,
  PATH_MERCHANT_PAYMENT_ACCOUNT,
  // PATH_PRICING,
  // PATH_PRICING_PAGES_CATEGORY,
  PATH_PAYMENT_CAPABILITIES,
  PATH_PAYMENT_CAPABILITIES_CATEGORY,
  PATH_PAYMENT_CAPABILITIES_CATEGORY_SUBCATEGORY,
  PATH_CLIENT_FUNDS_ACCOUNT,
  PATH_PAYMENT_CAPABILITIES_INC_MER,
  PATH_PAYMENT_CAPABILITIES_INC_MER_APM,
  PATH_PAYMENT_CAPABILITIES_INC_MER_CAR,
  PATH_PAYMENT_CAPABILITIES_INC_TRX,
  PATH_PAYMENT_CAPABILITIES_INC_TRX_CUR,
  PATH_PAYMENT_CAPABILITIES_OUT,
  // PATH_PAYMENT_CAPABILITIES_OUT_INS_BTR,
  PATH_PAYMENT_CAPABILITIES_OUT_INT_WIR,
  PATH_PAYMENT_CAPABILITIES_OUT_LOC_ACH,
  PATH_PAYMENT_CAPABILITIES_OUT_INS_WAL,
  PATH_PAYMENT_CAPABILITIES_OUT_PUS_CAR,
  PATH_PAYMENT_CAPABILITIES_INC_MER_APM_DD,
  // PATH_DEPOSIT_GUARANTEED_ACCOUNT,
  // PATH_DEPOSIT_GUARANTEED_ACCOUNT_BIZ,
  // PATH_DEPOSIT_GUARANTEED_ACCOUNT_PERSONAL,
  PATH_CARD_BIZ,
  PATH_CARD_PERSONAL,
  OLD_PATH_MEDIA_INFLUENCERS,
  OLD_PATH_ECOMMERCE,
  OLD_PATH_FINTECHS,
  OLD_PATH_ADVISORS,
  OLD_PATH_AFFILIATE_NETWORKS,
  OLD_PATH_OVERVIEW,
  OLD_PATH_CROSS_BORDER_PAYMENTS,
  OLD_PATH_API,
} from './constants'
import {
  HomePage,
  UseCasesPage,
  OnlineMarketPlacePage,
  GeneralLayout,
  AboutPage,
  BoardDirectorsPage,
  ExecutiveManagementPage,
  TalkToUs,
  ImpressumPage,
  PrivacyPolicyPage,
  NotFoundPage,
  CardsPage,
  ApplePayPage,
  NewsAndMediaPage,
  News,
  GooglePayPage,
  VirtualIbanAccountsPage,
  CrossBorderServicesCheckerModal,
  CorrespondentBanksPage,
  DataProcessorsPage,
  ApiPage,
  EntrepreneurAccountsPage,
  PartnershipsPage,
  FaqPage,
  // BusinessPricingPage,
  BusinessAccountPage,
  MerchantPaymentAccountPage,
  PaymentCapabilitiesPage,
  DepositGuaranteedAccountPage,
  ClientFundsAccountPage,
} from './components'
import { MessageSent } from './components/TalkToUs/MessageSent'

// const NewsAndMediaPage = lazy(() => import('./components/NewsAndMedia/lazyNewsAndMedia'))
// const News = lazy(() => import('./components/NewsAndMedia/lazyNews'))

// eslint-disable-next-line
function _ScrollToTop(props: any) {
  const { pathname } = useLocation()
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])
  return props.children
}
const ScrollToTop = withRouter(_ScrollToTop)

export const AppRoutes: React.FC = () => {
  const { pathname } = useLocation()
  const { t } = useTranslation()
  return (
    <>
      <Switch>
        <Route path={PATH_404} component={NotFoundPage} />
      </Switch>
      <CrossBorderServicesCheckerModal />
      {pathname !== PATH_404 && (
        <>
          <GeneralLayout>
            <ScrollToTop>
              <Switch>
                <Route path={PATH_ROOT} exact component={HomePage} />
                <Route path={PATH_USE_CASES} exact component={UseCasesPage} />
                <Route path={PATH_MARKET_PLACES} exact component={OnlineMarketPlacePage} />
                <Route path={PATH_BOARD_DIRECTORS} exact component={BoardDirectorsPage} />
                <Route path={PATH_EXECUTIVE_MANAGEMENT} exact component={ExecutiveManagementPage} />
                <Route path={PATH_NEWS_AND_MEDIA} exact component={NewsAndMediaPage} />
                <Route
                  path={PATH_VIRTUAL_IBAN_ACCOUNTS}
                  exact
                  component={VirtualIbanAccountsPage}
                />
                <Route
                  path={PATH_ENTREPRENEUR_ACCOUNTS}
                  exact
                  component={EntrepreneurAccountsPage}
                />
                <Route path={PATH_CONNECTIVITY} exact component={ApiPage} />
                <Route path={PATH_APPLE_PAY} exact component={ApplePayPage} />
                <Route path={PATH_GOOGLE_PAY} exact component={GooglePayPage} />
                <Route path={PATH_CARD} exact component={CardsPage} />
                <Route path={PATH_CARD_BIZ} exact component={CardsPage} />
                <Route path={PATH_CARD_PERSONAL} exact component={CardsPage} />
                <Route path={PATH_ABOUT} exact component={AboutPage} />
                <Route path={PATH_KLARPAY} exact component={AboutPage} />
                <Route path={PATH_TALKTOUS} exact component={TalkToUs} />
                {/* <Route path={PATH_PRICING} exact component={BusinessPricingPage} /> */}
                {/* <Route path={PATH_PRICING_PAGES_CATEGORY} exact component={BusinessPricingPage} /> */}
                <Route path={PATH_BUSINESS_ACCOUNT} exact component={BusinessAccountPage} />
                {/* <Route
                  path={PATH_DEPOSIT_GUARANTEED_ACCOUNT}
                  exact
                  component={DepositGuaranteedAccountPage}
                />
                <Route
                  path={PATH_DEPOSIT_GUARANTEED_ACCOUNT_BIZ}
                  exact
                  component={DepositGuaranteedAccountPage}
                />
                <Route
                  path={PATH_DEPOSIT_GUARANTEED_ACCOUNT_PERSONAL}
                  exact
                  component={DepositGuaranteedAccountPage}
                /> */}
                <Route path={PATH_CLIENT_FUNDS_ACCOUNT} exact component={ClientFundsAccountPage} />
                <Route
                  path={PATH_MERCHANT_PAYMENT_ACCOUNT}
                  exact
                  component={MerchantPaymentAccountPage}
                />
                <Route path={PATH_PAYMENT_CAPABILITIES} exact component={PaymentCapabilitiesPage} />
                <Route
                  path={PATH_PAYMENT_CAPABILITIES_INC_MER}
                  exact
                  component={PaymentCapabilitiesPage}
                />
                <Route
                  path={PATH_PAYMENT_CAPABILITIES_INC_MER_APM}
                  exact
                  component={PaymentCapabilitiesPage}
                />
                <Route
                  path={PATH_PAYMENT_CAPABILITIES_INC_MER_APM_DD}
                  exact
                  component={PaymentCapabilitiesPage}
                />
                <Route
                  path={PATH_PAYMENT_CAPABILITIES_INC_MER_CAR}
                  exact
                  component={PaymentCapabilitiesPage}
                />
                <Route
                  path={PATH_PAYMENT_CAPABILITIES_INC_TRX}
                  exact
                  component={PaymentCapabilitiesPage}
                />
                <Route
                  path={PATH_PAYMENT_CAPABILITIES_INC_TRX_CUR}
                  exact
                  component={PaymentCapabilitiesPage}
                />
                <Route
                  path={PATH_PAYMENT_CAPABILITIES_OUT}
                  exact
                  component={PaymentCapabilitiesPage}
                />
                {/* <Route
                  path={PATH_PAYMENT_CAPABILITIES_OUT_INS_BTR}
                  exact
                  component={PaymentCapabilitiesPage}
                /> */}
                <Route
                  path={PATH_PAYMENT_CAPABILITIES_OUT_INT_WIR}
                  exact
                  component={PaymentCapabilitiesPage}
                />
                <Route
                  path={PATH_PAYMENT_CAPABILITIES_OUT_LOC_ACH}
                  exact
                  component={PaymentCapabilitiesPage}
                />
                <Route
                  path={PATH_PAYMENT_CAPABILITIES_OUT_INS_WAL}
                  exact
                  component={PaymentCapabilitiesPage}
                />
                <Route
                  path={PATH_PAYMENT_CAPABILITIES_OUT_PUS_CAR}
                  exact
                  component={PaymentCapabilitiesPage}
                />
                <Route
                  path={PATH_PAYMENT_CAPABILITIES_CATEGORY}
                  exact
                  component={PaymentCapabilitiesPage}
                />
                <Route
                  path={PATH_PAYMENT_CAPABILITIES_CATEGORY_SUBCATEGORY}
                  exact
                  component={PaymentCapabilitiesPage}
                />
                <Route path={PATH_PARTNERSHIPS} exact component={PartnershipsPage} />
                <Route path={PATH_IMPRESSUM} exact component={ImpressumPage} />
                <Route path={PATH_IMPRINT} exact component={ImpressumPage} />
                <Route path={PATH_CORRESPONDENT_BANKS} exact component={CorrespondentBanksPage} />
                <Route path={PATH_DATA_PROCESSORS} exact component={DataProcessorsPage} />
                <Route path={PATH_PRIVACY_POLICY} exact component={PrivacyPolicyPage} />
                <Route path={PATH_NEWS_PAGES} exact component={NewsAndMediaPage} />
                <Route path={PATH_NEWS_PAGES_CATEGORY} exact component={NewsAndMediaPage} />
                <Route path={PATH_NEWS_PAGES_CATEGORY_PAGES} exact component={NewsAndMediaPage} />
                <Route path={PATH_NEWS_ENTRIES} component={News} />
                <Route path={PATH_FAQ} component={FaqPage} />
                <Route path={PATH_MESSAGE_SENT} exact component={MessageSent} />
                {/* OLD ROUTES START */}
                <Route path={OLD_PATH_OVERVIEW} exact component={UseCasesPage} />
                <Route path={OLD_PATH_MEDIA_INFLUENCERS} exact component={UseCasesPage} />
                <Route path={OLD_PATH_AFFILIATE_NETWORKS} exact component={UseCasesPage} />
                <Route path={OLD_PATH_ECOMMERCE} exact component={UseCasesPage} />
                <Route path={OLD_PATH_FINTECHS} exact component={UseCasesPage} />
                <Route path={OLD_PATH_ADVISORS} exact component={ExecutiveManagementPage} />
                <Route path={OLD_PATH_API} exact component={ApiPage} />
                <Route
                  path={OLD_PATH_CROSS_BORDER_PAYMENTS}
                  exact
                  component={PaymentCapabilitiesPage}
                />
                {/* OLD ROUTES FINISH */}
                <Redirect to={PATH_404} />
              </Switch>
              <CookieConsent
                declineButtonText={t('COMMON-Cookie-Button-Decline')}
                style={{
                  fontFamily: '"Helvetica Neue", "Arial", sans-serif',
                  backgroundColor: 'rgba(0, 0, 0, 0.9)',
                  textAlign: 'center',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignContent: 'center',
                }}
                contentStyle={{
                  flex: 'inherit',
                }}
                buttonStyle={{
                  color: '#fff',
                  fontFamily: '"Helvetica Neue", "Arial", sans-serif',
                  fontWeight: 'bold',
                  backgroundColor: '#EF2828',
                }}
              >
                {t('COMMON-Cookie-Text')}
              </CookieConsent>
            </ScrollToTop>
          </GeneralLayout>
        </>
      )}
    </>
  )
}

/* 
{
      "loc":"https://www.bivial.com/pricing",
      "lastmod":"2022-06-08"
   },
   {
      "loc":"https://www.bivial.com/pricing/application",
      "lastmod":"2022-06-08"
   },
   {
      "loc":"https://www.bivial.com/pricing/account",
      "lastmod":"2022-06-08"
   },
   {
      "loc":"https://www.bivial.com/pricing/banks-wallet",
      "lastmod":"2022-06-08"
   },
   {
      "loc":"https://www.bivial.com/pricing/merchant",
      "lastmod":"2022-06-08"
   },
   {
      "loc":"https://www.bivial.com/pricing/card",
      "lastmod":"2022-06-08"
   },

*/
