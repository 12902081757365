import { FC, default as React } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'

import {
  CapabilitiesTable,
  WebsiteTitle,
  SubSubFooter,
  HeroImage,
  FeatureBox,
  FeatureElements,
  FeatureIcons,
} from '../Common'
import imgHero2x from '../../assets/images/img-business-accounts-header.png'

import icon1 from '../../assets/icons/fin-rep-rec-business-accounts.svg'
import icon2 from '../../assets/icons/customer-support-business-accounts.svg'
import icon3 from '../../assets/icons/visa-business-accounts.svg'
import icon4 from '../../assets/icons/fx-business-accounts.svg'

import {
  PATH_CARD,
  PATH_CLIENT_FUNDS_ACCOUNT,
  // PATH_DEPOSIT_GUARANTEED_ACCOUNT,
  PATH_PAYMENT_CAPABILITIES_INC_MER_APM,
  PATH_PAYMENT_CAPABILITIES_INC_MER_APM_DD,
  PATH_PAYMENT_CAPABILITIES_INC_MER_CAR,
  PATH_PAYMENT_CAPABILITIES_INC_TRX_CUR,
  PATH_PAYMENT_CAPABILITIES_OUT_INS_WAL,
  PATH_PAYMENT_CAPABILITIES_OUT_INT_WIR,
  PATH_PAYMENT_CAPABILITIES_OUT_LOC_ACH,
  PATH_PAYMENT_CAPABILITIES_OUT_PUS_CAR,
  PATH_VIRTUAL_IBAN_ACCOUNTS,
} from '../../constants'
import { tagManagerHandler } from '../../utils'
import { Hidden } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  paddingBox: {
    padding: theme.spacing(2.5, 5),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1.5, 2),
    },
  },
  simpleFeatureContainer: {
    maxWidth: '1050px',
    margin: '0 auto',
    marginBottom: theme.spacing(6),
  },
  iconFeatureContainer: {
    maxWidth: '700px',
    margin: '0 auto',
    marginBottom: theme.spacing(6),
  },
  featureContainer: {
    maxWidth: '1100px',
    margin: '0 auto',
  },
  mediaObjectContainer: {
    marginTop: theme.spacing(12),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(6),
    },
  },
  bannerContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(12),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(4),
    },
  },
}))

type SimpleFeatureElement = { title: string; content: string }

type IconFeatureElement = { title: string; icon: string }

type FeatureElement =
  | { type: 'title' | 'subtitle' | 'section' | 'content'; text: string }
  | { type: 'link'; text: string; linkTarget: string }
  | { type: 'separator' }

const features: SimpleFeatureElement[] = [
  {
    title: 'Business-Account-H2',
    content: 'Business-Account-P2',
  },
  {
    title: 'Business-Account-H3',
    content: 'Business-Account-P3',
  },
  {
    title: 'Business-Account-H4',
    content: 'Business-Account-P4',
  },

  {
    title: 'Business-Account-H5',
    content: 'Business-Account-P5',
  },

  {
    title: 'Business-Account-H6',
    content: 'Business-Account-P6',
  },
  {
    title: 'Business-Account-H7',
    content: 'Business-Account-P7',
  },
]

const icons: IconFeatureElement[] = [
  {
    title: 'Business-Account-H8',
    icon: icon1,
  },
  {
    title: 'Business-Account-H9',
    icon: icon2,
  },
  {
    title: 'Business-Account-H10',
    icon: icon3,
  },
  {
    title: 'Business-Account-H11',
    icon: icon4,
  },
]

const incomingPaymentsElements: FeatureElement[] = [
  {
    type: 'subtitle',
    text: 'Business-Account-H12',
  },
  {
    type: 'link',
    text: 'Business-Account-P121',
    linkTarget: PATH_PAYMENT_CAPABILITIES_INC_TRX_CUR,
  },
  {
    type: 'link',
    text: 'Business-Account-P122',
    linkTarget: PATH_PAYMENT_CAPABILITIES_INC_MER_CAR,
  },
  {
    type: 'link',
    text: 'Business-Account-P123',
    linkTarget: PATH_PAYMENT_CAPABILITIES_INC_MER_APM,
  },
  {
    type: 'link',
    text: 'Business-Account-P124',
    linkTarget: PATH_PAYMENT_CAPABILITIES_INC_MER_APM_DD,
  },
  // {
  //   type: 'link',
  //   text: 'Business-Account-P125',
  //   linkTarget: PATH_CARD,
  // },
  {
    type: 'link',
    text: 'Business-Account-P126',
    linkTarget: PATH_VIRTUAL_IBAN_ACCOUNTS,
  },
  {
    type: 'separator',
  },
]

const outgoingPaymentsElements: FeatureElement[] = [
  {
    type: 'subtitle',
    text: 'Business-Account-H13',
  },
  {
    type: 'link',
    text: 'Business-Account-P131',
    linkTarget: PATH_PAYMENT_CAPABILITIES_OUT_INT_WIR,
  },
  {
    type: 'link',
    text: 'Business-Account-P132',
    linkTarget: PATH_PAYMENT_CAPABILITIES_OUT_LOC_ACH,
  },
  {
    type: 'link',
    text: 'Business-Account-P133',
    linkTarget: PATH_PAYMENT_CAPABILITIES_OUT_INS_WAL,
  },
  {
    type: 'link',
    text: 'Business-Account-P134',
    linkTarget: PATH_PAYMENT_CAPABILITIES_OUT_PUS_CAR,
  },
  // {
  //   type: 'link',
  //   text: 'Business-Account-P135',
  //   linkTarget: PATH_CARD,
  // },
  {
    type: 'separator',
  },
]

const seeAlsoElements: FeatureElement[] = [
  {
    type: 'subtitle',
    text: 'Business-Account-H14',
  },
  {
    type: 'link',
    text: 'Business-Account-P141',
    linkTarget: PATH_CARD,
  },
  // {
  //   type: 'link',
  //   text: 'Business-Account-P142',
  //   linkTarget: PATH_DEPOSIT_GUARANTEED_ACCOUNT,
  // },
  {
    type: 'link',
    text: 'Business-Account-P143',
    linkTarget: PATH_CLIENT_FUNDS_ACCOUNT,
  },
  // {
  //   type: 'link',
  //   text: 'Business-Account-P144',
  //   linkTarget: PATH_CARD,
  // },
  {
    type: 'separator',
  },
]

export const BusinessAccountPage: FC = () => {
  const classes = useStyles()

  tagManagerHandler('PageBusinessAccount')

  return (
    <>
      <Box className={classes.paddingBox}>
        <Container>
          <WebsiteTitle title={'Business-Account-H1'} subtitle={'Business-Account-P11'} />
          <HeroImage image={imgHero2x} />
          <Grid container>
            <Grid item xs={12} className={classes.simpleFeatureContainer}>
              <FeatureElements elements={features} />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} className={classes.iconFeatureContainer}>
              <FeatureIcons elements={icons} />
            </Grid>
          </Grid>
          <Grid container className={classes.featureContainer} spacing={2}>
            <Grid item xs={12} md={4}>
              <FeatureBox isBlackBg={true} elements={incomingPaymentsElements} />
            </Grid>
            <Grid item xs={12} md={4}>
              <FeatureBox isBlackBg={true} elements={outgoingPaymentsElements} />
            </Grid>
            <Grid item xs={12} md={4}>
              <FeatureBox isBlackBg={true} elements={seeAlsoElements} />
            </Grid>
          </Grid>
          {/* <Grid container>
            <Grid item xs={12}>
              <CapabilitiesTable />
            </Grid>
          </Grid> */}
        </Container>
      </Box>
      <Hidden mdUp>
        <Box mb={6} />
      </Hidden>
      <SubSubFooter />
    </>
  )
}
