import { FC, default as React } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import CardMedia from '@material-ui/core/CardMedia'

import { AboutBivial } from './AboutBivial'
import { WebsiteTitle, SubSubFooter, FeatureBox, MediaObject } from '../Common'
import imgHero2x from '../../assets/images/img-bivial-dashboard.jpg'
import city from '../../assets/images/img-about-us-bivial.png'

import {
  PATH_BUSINESS_ACCOUNT,
  PATH_CARD,
  PATH_CLIENT_FUNDS_ACCOUNT,
  PATH_ENTREPRENEUR_ACCOUNTS,
  // PATH_DEPOSIT_GUARANTEED_ACCOUNT,
  PATH_PAYMENT_CAPABILITIES_INC_TRX_CUR,
  PATH_PAYMENT_CAPABILITIES_INC_MER_APM_DD,
  PATH_VIRTUAL_IBAN_ACCOUNTS,
  PATH_PAYMENT_CAPABILITIES_INC_MER_CAR,
  PATH_PAYMENT_CAPABILITIES_INC_MER_APM,
  PATH_PAYMENT_CAPABILITIES_OUT_INT_WIR,
  PATH_PAYMENT_CAPABILITIES_OUT_LOC_ACH,
  PATH_PAYMENT_CAPABILITIES_OUT_INS_WAL,
  PATH_PAYMENT_CAPABILITIES_OUT_PUS_CAR,
  PATH_CONNECTIVITY,
} from '../../constants'
import { tagManagerHandler } from '../../utils'
import { Hidden } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  paddingBox: {
    padding: theme.spacing(2.5, 5),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1.5, 2),
    },
  },
  featureContainer: {
    maxWidth: '1100px',
    margin: '0 auto',
  },
  mediaObjectContainer: {
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(-4),
    },
  },
  bannerContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(12),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(4),
    },
  },
  hero: {
    margin: '10px auto 60px',
    maxWidth: '1000px',
    height: 'auto',
  },
  rightLine: {
    borderRight: '1px solid #E0E0E0',
    [theme.breakpoints.down('sm')]: {
      borderRight: 'none',
    },
  },
}))

type FeatureElement =
  | { type: 'title' | 'subtitle' | 'section' | 'content'; text: string }
  | { type: 'link'; text: string; linkTarget: string }
  | { type: 'separator' }

const accountElements: FeatureElement[] = [
  {
    type: 'title',
    text: 'Home-H2',
  },
  {
    type: 'subtitle',
    text: 'Home-P2',
  },
  {
    type: 'content',
    text: 'Home-P21',
  },
  {
    type: 'link',
    text: 'Home-P22',
    linkTarget: PATH_BUSINESS_ACCOUNT,
  },
  // {
  //   type: 'link',
  //   text: 'Home-P23',
  //   linkTarget: PATH_DEPOSIT_GUARANTEED_ACCOUNT,
  // },
  {
    type: 'link',
    text: 'Home-P24',
    linkTarget: PATH_CLIENT_FUNDS_ACCOUNT,
  },
  {
    type: 'link',
    text: 'Home-P25',
    linkTarget: PATH_CARD,
  },
  {
    type: 'separator',
  },
  {
    type: 'subtitle',
    text: 'Home-P3',
  },
  {
    type: 'content',
    text: 'Home-P31',
  },
  {
    type: 'link',
    text: 'Home-P32',
    linkTarget: PATH_ENTREPRENEUR_ACCOUNTS,
  },
  {
    type: 'link',
    text: 'Home-P33',
    linkTarget: PATH_CARD,
  },
]

const capabilityElementsA: FeatureElement[] = [
  {
    type: 'title',
    text: 'Home-H4',
  },
  {
    type: 'subtitle',
    text: 'Home-P4',
  },
  {
    type: 'content',
    text: 'Home-P41',
  },
  {
    type: 'section',
    text: 'Home-P42',
  },
  {
    type: 'link',
    text: 'Home-P43',
    linkTarget: PATH_PAYMENT_CAPABILITIES_INC_TRX_CUR,
  },
  {
    type: 'link',
    text: 'Home-P44',
    linkTarget: PATH_PAYMENT_CAPABILITIES_INC_MER_APM_DD,
  },
  // {
  //   type: 'link',
  //   text: 'Home-P45',
  //   linkTarget: PATH_BUSINESS_ACCOUNT,
  // },
  {
    type: 'link',
    text: 'Home-P46',
    linkTarget: PATH_VIRTUAL_IBAN_ACCOUNTS,
  },
  {
    type: 'section',
    text: 'Home-P47',
  },
  {
    type: 'link',
    text: 'Home-P48',
    linkTarget: PATH_PAYMENT_CAPABILITIES_INC_MER_CAR,
  },
  {
    type: 'link',
    text: 'Home-P49',
    linkTarget: PATH_PAYMENT_CAPABILITIES_INC_MER_APM,
  },
  {
    type: 'subtitle',
    text: 'Home-P50',
  },
  {
    type: 'content',
    text: 'Home-P51',
  },
  // {
  //   type: 'link',
  //   text: 'Home-P52',
  //   linkTarget: PATH_ENTREPRENEUR_ACCOUNTS,
  // },
  {
    type: 'separator',
  },
]

const capabilityElementsB: FeatureElement[] = [
  {
    type: 'subtitle',
    text: 'Home-P6',
  },
  {
    type: 'content',
    text: 'Home-P61',
  },
  {
    type: 'link',
    text: 'Home-P62',
    linkTarget: PATH_PAYMENT_CAPABILITIES_OUT_INT_WIR,
  },
  {
    type: 'link',
    text: 'Home-P63',
    linkTarget: PATH_PAYMENT_CAPABILITIES_OUT_LOC_ACH,
  },
  {
    type: 'link',
    text: 'Home-P64',
    linkTarget: PATH_PAYMENT_CAPABILITIES_OUT_INS_WAL,
  },
  {
    type: 'link',
    text: 'Home-P65',
    linkTarget: PATH_PAYMENT_CAPABILITIES_INC_MER_CAR,
  },
  // {
  //   type: 'link',
  //   text: 'Home-P66',
  //   linkTarget: PATH_BUSINESS_ACCOUNT,
  // },
  {
    type: 'separator',
  },
  {
    type: 'subtitle',
    text: 'Home-P7',
  },
  {
    type: 'content',
    text: 'Home-P71',
  },
  {
    type: 'link',
    text: 'Home-P72',
    linkTarget: PATH_CONNECTIVITY,
  },
  // {
  //   type: 'link',
  //   text: 'Home-P73',
  //   linkTarget: PATH_BUSINESS_ACCOUNT,
  // },
]

export const HomePage: FC = () => {
  const classes = useStyles()

  tagManagerHandler('PageHome')

  return (
    <>
      <Box className={classes.paddingBox}>
        <Container>
          <WebsiteTitle title={'Home-H1'} subtitle={'Home-P11'} />
          <CardMedia component="img" alt="" image={imgHero2x} className={classes.hero} />
          <Hidden smDown>
            <Box mt={12} />
          </Hidden>
          <Grid container className={classes.featureContainer}>
            <Grid item xs={12} md={4}>
              <Box className={classes.rightLine}>
                <FeatureBox isBlackBg={false} elements={accountElements} />
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <FeatureBox isBlackBg={false} elements={capabilityElementsA} />
            </Grid>
            <Grid item xs={12} md={4}>
              <Hidden smDown>
                <Box mb={6} />
              </Hidden>
              <Hidden mdUp>
                <Box mb={-9} />
              </Hidden>
              <FeatureBox isBlackBg={false} elements={capabilityElementsB} />
            </Grid>
            <Grid container className={classes.mediaObjectContainer}>
              <MediaObject imagePlacement={'right'} image={city}>
                <AboutBivial />
              </MediaObject>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <SubSubFooter />
    </>
  )
}
